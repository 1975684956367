import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`The Rating component is a user interface element designed to collect and display user feedback or ratings for products, services, or content.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/rating/guideline-rating.svg",
      "alt": "anatomy select field"
    }}></img>
    <ol>
      <li parentName="ol">{`A set of symbols (e.g., stars, thumbs, hearts) representing the available rating options.`}</li>
      <li parentName="ol">{`Visual feedback when users hover over or interact with a rating symbol, indicating interactivity.`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Rating components can be used in a variety of contexts, such as:`}</p>
    <ul>
      <li parentName="ul">{`Product pages`}</li>
      <li parentName="ul">{`Review sites`}</li>
      <li parentName="ul">{`Social media platforms`}</li>
      <li parentName="ul">{`Customer surveys`}</li>
      <li parentName="ul">{`Feedback forms`}</li>
    </ul>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li>
        When you want to collect feedback from users about a product, service,
        or experience.
      </li>
      <li>
        When you want to allow users to compare different products, services, or
        experiences based on their ratings.
      </li>
      <li>
        When you want to use ratings to improve your product, service, or
        experience.
      </li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li>When you need to collect detailed feedback from users.</li>
      <li>
        When you need to collect ratings for a complex product, service, or
        experience.
      </li>
      <li>When you need to collect ratings from a large number of users.</li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`To ensure that the Rating component is accessible to all users, consider the following guidelines:`}</p>
    <ul>
      <li parentName="ul">{`Make sure that the rating component fully navigable and interact using a keyboard.`}</li>
      <li parentName="ul">{`Ensure to avoid relying solely on color to convey meaning, and ensure sufficient contrast for symbol visibility.`}</li>
      <li parentName="ul">{`Maintain visible focus indicators on interactive elements for keyboard users.`}</li>
      <li parentName="ul">{`Provide appropriate ARIA labels and roles to make the rating symbols and labels understandable to screen reader users.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Use colors with good contrast." aspectRatio="16:8" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/rating/rating-a11y-do.svg",
          "alt": "Alt text"
        }}></img>
  </DoDont>
  <DoDont type="dont" captionTitle="Don't" caption="Never use colors with poor contrast, like similar shade of the same color." aspectRatio="16:8" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/rating/rating-a11y-dont.svg",
          "alt": "Alt text"
        }}></img>
  </DoDont>
    </DoDontRow>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Choose right symbol for rating scale." aspectRatio="16:8" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/rating/rating-a11y-do-2.svg",
          "alt": "Alt text"
        }}></img>
  </DoDont>
  <DoDont type="dont" captionTitle="Don't" caption="Never use symbol wihtout clear purpose." aspectRatio="16:8" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/rating/rating-a11y-dont-2.svg",
          "alt": "Alt text"
        }}></img>
  </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When implementing the Rating component, consider the following content guidelines:`}</p>
    <ul>
      <li parentName="ul">{`Include a concise label or title that indicates what is being rated.`}</li>
      <li parentName="ul">{`Use clear and universally recognizable symbols (e.g., stars) for user ratings.`}</li>
      <li parentName="ul">{`Optionally, include tooltips that provide additional context when users hover over rating symbols.`}</li>
      <li parentName="ul">{`Display a confirmation message after users submit their rating to acknowledge their input.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      